.ChargerDescBox {
  width: 100%;
  height: 100%;
  max-height: 460px;
  min-height: 460px;
  margin-top: 10px;
  display: flex;
  justify-content: center;
}

.ChargerDescContent{
  background-color: white;
  border-radius: 5px;
  width: 95%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ChargerDescTopBox{
  margin-top: 20px;
  display: flex;
  color: black;
  justify-content: center;
  width: 100%;
  padding-bottom: 30px;
}


.ChargerDescTopBox > img {
  width: 100px;
  margin-left: 10px;
}

.ChargerDescTopTitle{
  width: 60%;
  margin-left: 20px;
  text-align: left;
  font-size: 14px;
  font-weight: 600;
  display: inherit;
  flex-direction: column;
}

.ChargerDescTopTitle > button {
  border: none;
  margin-top: 10px;
  width: 170px;
  height: 40px;
  border-radius: 20px;
  color: black;
  font-size: 15px;
  font-weight: 600;
  background-color: #00aaff;
}

.ChargerDescTitleBottom{
  color: black;
  display: inherit;
  justify-content: space-between;
  width: 100%;
  padding: 5px;
  font-size: 14px;
  border-top: 1px solid #ccc;
}

.ChargerDescList{
  text-align: left;
  width: 50%;
  font-weight: 600;
  font-size: 13px;
}