.ChargerFinderContent{
  width: 95%;
  max-width: 600px;
  background: black;
  display: table;
  border-radius: 5px;
  margin: auto;
  margin-top: 10px;
  padding: 15px 0 15px 0;
}

.ChargerFinderResult {
  color: black;
  background-color: white;
  width: 95%;
  max-width: 620px;
  margin: auto;
  margin-bottom: 5px;
  border-radius: 5px;
}

.ChargerFinderContent h1 {
  margin-top: 10px;
  margin-bottom: 20px;
}

.ChargerFinderContent .search_input {
  margin: 0 7px 0 0;
  width: calc(100% - 110px);
  height: 50px;
  border-bottom: #00aaff solid 1px;
  display: inline-block;
}

.ChargerFinderContent .search_input input {
  width: calc(100% - 20px);
  height: 100%;
  color: #fff;
  font-size: 1.2rem;
  background: #0000;
  border: none;
  padding: 0 10px;
  vertical-align: middle;
}

.ChargerFinderContent .search {
  width: 50px;
  height: 50px;
  border: none;
  border-radius: 5px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
}

.ChargerFinderContent .search img {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.ChargerFinderResult .info {
  text-align: left;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  width: calc(100% - 85px);
  color: black;
  margin: 2px 0;
  vertical-align: middle;
  display: inline-block;
}

.ChargerFinderResult .address {
  text-align: left;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  width: calc(100%);
  color: #444;
  margin: 2px 0;
  vertical-align: middle;
  display: inline-block;
}

.ChargerFinderResult .address img {
  width: 30px;
  height: 20px;
  margin: auto;
  margin-right: 10px;
}

.ChargerFinderResultList {
  max-height: calc(100% - 220px);
  overflow-y: auto;
}

.ChargerFinderResult img {
  width: 50px;
  height: 50px;
  margin: 10px 5px 10px 0;
  vertical-align: middle;
  display: inline-block;
}

.ChargerFinderPagination {
  margin-top: 10px;
  font-size: 1.3rem;
  color: white;
}

.ChargerFinderPaginationButton {
  display: inline-block;
  vertical-align: middle;
  margin: 0 5px;
}

.EasterEgg {
  -webkit-animation-name: animation;
  -webkit-animation-duration: 0.5s;
  animation-name: animation;
  animation-duration: 0.5s;
  animation-iteration-count: infinite;
}

.EasterEgg2{
  
    -webkit-animation-name: animation2;
    -webkit-animation-duration: 14s;
    animation-name: animation2;
    animation-duration: 14s;
}

@keyframes animation {
  from {transform: rotate(0deg)}
  to {transform: rotate(360deg)}
}

@keyframes animation2{
  from {filter: grayscale(0%) blur(0px);}
  to{filter: grayscale(100%) blur(3px);}
}

