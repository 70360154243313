.Logo {
  width: 66px;
  height: 28px;
  margin-right: 23px;
  margin-top: -12px;
  right: 0;
  position: absolute;
}

.MenuToggle {
  margin: 0;
  height: 30px;
  margin-top: 2px;
  margin-left: 4px;
  position: relative;
}

.MenuToggle input {
  display: block;
  position: absolute;
  width: 40px;
  height: 35px;
  left: -7px;
  top: -7px;
  opacity: 0;
  z-index: 4;
  cursor: pointer;
}

.MenuToggle .NewMessagesIcon {
	position: absolute;
	top: -12px;
	right: -12px;
	z-index: 3;
	background: red;
	color: white;
	width: 24px;
	height: 24px;
	border-radius: 12px;
	font-size: 18px;
	line-height: 22px;
	font-weight: bold;
}

.MenuToggle span {
  display: block;
  width: 33px;
  height: 5px;
  margin-bottom: 6px;
  background: #ffffff;
  z-index: 1;
  transform-origin: 4px 0px;
  transition: all 0.3s ease;
  border-radius: 3px;
}

.MenuToggle span:first-child {
  transform-origin: 0% 0%;
}

.MenuToggle span:nth-last-child(2) {
  transform-origin: 0% 100%;
}

.MenuToggle input:checked ~ span {
  opacity: 1;
  transform: rotate(45deg) translate(-13px, -12px);
}

.MenuToggle input:checked ~ span:nth-last-child(2) {
  opacity: 0;
  transform: rotate(0deg);
}

.MenuToggle input:checked ~ span:nth-last-child(3) {
  transform: rotate(-45deg) translate(-19px, 12px);
}

