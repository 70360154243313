.UserStatsChargingArea {
  width: 95%;
  max-width: 550px;
  background: white;
  margin: auto;
  margin-top: 10px;
  padding: 10px 0;
  color:black;
  border-radius: 5px;
}

.Statbox{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.Statbox > img {
  width: 80px;
}

.stat{
  width: 200px;
  display: flex;
  justify-content: space-between;
  padding: 8px;
}

.stat div:last-child{
  font-weight: 650;
}

.stat:nth-last-child(2){
  border-bottom: 1px solid #4444;
  border-top:  1px solid #4444;
}

.UserChargingHistory{
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 80px;
}

.UserChargingHistory .info {
  float: left;
  text-align: left;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  width: calc(100% - 85px);
  margin-left: 10px;
  font-weight: bold;
}

.UserStatsArea .ChargingList {
  margin-top: 10px;
  max-height: calc(100% - 180px);
}

.info2 {
  float: left;
  text-overflow: ellipsis;
  width: calc(100% - 85px);
  text-align: left;
  margin-left: 10px;
  overflow-x: hidden;
  font-size: 12px;
  color: #444;
  margin-top: 5px;
}
