.UserReservationBox {
}

.UserReservationTitle {
  font-size: 20px;
  margin-top: 10px;
  font-weight: 600;
  margin-bottom: 20px;
}

.UserReservationContent {
  width: 100%;
  height: calc(100% - var(--navbar-height));
  margin-top: 10px;
}

.UserReservations {
  background-color: white;
  width: 90%;
  max-width: 500px;
  margin: auto;
  border-radius: 8px;
  margin-top: 10px;
  padding: 5px 0;
  color: black;
  font-size: 16px;
  display: flex;
  align-items: center;
}

.UserReservations:last-child {
  margin-bottom: 10px;
}

.UserReservationIcon > svg {
  color: var(--light-blue-color);
  font-size: 50px;
}

.UserReservationDesc {
  width: 80%;
  text-align: left;
}

.reservation-dates {
  color: #555;
  font-size: 12px;
}
.reservation-name {
  vertical-align: top;
  font-size: 16px;
  margin-bottom: 5px;
}

.UserReservationCanceled > svg {
  margin-top: 2px;
  font-size: 50px;
  color: rgb(255, 95, 95);
}

.UserCurrentReservations {
  background-color: rgba(116, 255, 116, 0.822) !important;
}

.TitleCurrentUserReservation {
  font-size: 0.9rem;
  width: 40px;
  margin: -10px;
  margin-right: 5px;
  display: flex;
}

.TitleCurrentUserReservation > div {
  transform: rotate(-90deg);
}

.UserReservationWithout {
  font-style: italic;
  margin-top: 20px;
}
