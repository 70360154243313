.AdminPanel {
  border: 1px solid white;
  width: 200px;
  min-height: 600px;
  margin-left: 50px;
  float: left;
}

.AdminContent {
  border: 1px solid white;
  margin-left: 300px;
  margin-right: 50px;
  min-height: 560px;
  padding: 20px;
}

h1 {
  margin-bottom: 40px;
}

h4 {
  cursor: pointer;
}

.AdminQuery {
  position: absolute;
  right: 0;
  top: 0;
}

.AdminSearch {
  position: absolute;
  left: 0;
  top: 0;
}

.AdminContainer {
  position: relative;
  border: 1px #0000 solid;
}

.AdminTable {
  margin-top: 40px;
}

.AdminForm {
  text-align: left;
  margin-left: 40%;
  margin-top: 30px;
}

.AdminNotification {
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 350px;
  height: 60px;
  color: red;
  text-decoration-line: underline;
  font-size: 24px;
  text-decoration-style: dashed;
  background-color: black;
}

@media only screen and (max-width: 1365px) {
  .AdminContent {
    display: none;
  }
  .AdminPanel {
    display: none;
  }
}
@media only screen and (min-width: 1365px) {
  .AdminNotification {
    display: none;
  }
}