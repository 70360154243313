.MessagesContent
{
	overflow-y: auto !important;
	overflow-x: hidden !important;
}

.MessagesReceiversList a {
	text-decoration: none;
}

.chat-header {
	background-color: #00A0FD;
	color: black;
	text-transform: uppercase;
	font-size: 14px;
	width: 90%;
	margin: auto;
	height: 40px;
	margin-top: 10px;
	border-radius: 40px;
	font-weight: 500;
	letter-spacing: 1px;
	line-height: 40px;
	margin-bottom: 10px;
}

.MessagesReceiversList {
	position: absolute;
	width: 100%;
	height: calc(100% - 20px);
	top: 0;
	left: 0%;
	transition: left 0.5s ease;
	display: flex;
	flex-direction: column;
	padding: 10px 0;
	overflow-y: scroll;
}

.MessagesReceiver {
	width: 88%;
	padding: 10px 0;
	margin: 0 auto;
	position: relative;
	cursor: pointer;
	background-color: white;
	color: black;
	margin-top: 10px;
	border-radius: 10px;
	padding: 10px;
}

.MessagesReceiver:after {
	content: "";
	display: block;
	position: absolute;
	bottom: -30px;
	height: 30px;
	width: 100%;
}

.MessagesReceiverAvatar {
	width: 60px;
	height: 60px;
	border-radius: 40px;
	float: left;
}

.MessagesReceiverInfo {
	float: left;
	text-align: left;
	margin-left: 14px;
}


.MessagesReceiverTime {
	color: #888;
	text-align: left;
	font-size: 0.8rem;
	padding: 1px 0;
	margin-top: 8px;
}

.MessagesReceiverName {
	text-align: left;
	padding: 1px 0;
	color: black;
	opacity: 1 !important;
	font-size: 18px;
	font-weight: 500;
}

.MessagesReceiversLoading {
	margin-top: 20px;
}

.MessagesReceiversLoading svg {
	color: #00A0FD;
}