.ReportChargerCordsBox{
  transition: left 0.5s ease;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.ReportChargerCordsContent{
  width: 95%;
  max-width: 550px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  border-radius: 5px;
  height: 210px;
  margin-top: 20px;
}
 .Cords {
   color: black;
   margin-top: 20px;
   margin-bottom: 20px;
 }

 .Cords > div:first-child{
   text-align: left;
   margin-bottom: 10px;
   letter-spacing: 3px;
   color: #aaa
 }

.ReportChargerCordsBox > div:last-child{
  margin-bottom: 20px;
  margin-right: 40px;
  width: 100%;
  max-width: 550px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.ReportChargerCordsBox > div >button {
  width: 120px;
  height: 40px;
  border-radius: 20px;
  border: none;
  background-color: #00a0fd;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 600;
}


.ReportChargerListBox {
  transition: left 0.5s ease;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
}

.ReportChargerTitleBox {
  color: white;
  position: relative;
  padding: 5px 0;
  display: flex;
  align-items: center;
  background: linear-gradient(
    0deg,
    rgb(6, 36, 95) 0px,
    rgb(20, 70, 150) 8px,
    rgb(0, 212, 255) 8px,
    rgb(0, 212, 255) 10px,
    rgb(6, 36, 95) 10px,
    rgb(6, 36, 95) 100%
  );
  padding-bottom: 20px;
  width: 100%;
}

.ReportChargerTitle {
  margin: auto;
  font-size: 30px;
  font-weight: bold;
}

.ReportChargerContent {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 520px;
  color: black;
  width: 95%;
  max-width: 600px;
  border-radius: 5px;
  background-color: white;
}

.ReportChargerContent > div {
  width: 95%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ReportChargerInput {
  width: 95%;
  height: 50px;
  padding: 10px;
}

.ReportChargerInput > div {
  text-align: left;
  width: 100%;
  text-transform: uppercase;
  letter-spacing: 3px;
  color: #aaa;
  margin-top: 20px;
}

.ReportChargerInput > input {
  border: none;
  border-bottom: 1px solid #00a0fd;
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 1.1rem;
}


.ReportChargerSelect {
  color: black;
  width: 90%;
  margin: auto;
}

.ReportChargerSelect div {
  width: 100%;
  text-align: left;
  margin-bottom: 10px;
  text-transform: uppercase;
  letter-spacing: 3px;
  color: #aaa;
}

.ReportChargerSelect select {
  border: none;
  background-color: transparent;
  width: 100%;
  height: 60px;
  margin: auto;
  margin-bottom: 30px;
  font-size: 1rem;
}

.ReportChargerHourBox{
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 30px;
}

.TitleHourOpen{
  color: #aaa;
  width: 95%;
  text-align: left;
  text-transform: uppercase;
  letter-spacing: 3px;
  margin-bottom: 5px;
}

.ReportChargerHourContentFrom,
.ReportChargerHourContentTo {
  width: 50%;
  float: left;
  background-color: transparent;
  padding: 5px;
  color: rgba(255, 255, 255, 0.7);
  align-items: center;
}

.ReportChargerHourContentFrom,
.ReportChargerHourContentTo{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.ReportChargerHourContentFrom> div>p,
.ReportChargerHourContentTo>div>p{
  font-size: 10px;
  margin-top: 2px;
  margin-left: 16px;
  color: #aaa
}

.ReportChargerHourContentFrom>div> input,
.ReportChargerHourContentTo>div> input {
  background-color: transparent;
  border: none;
  border-bottom: #00aaff solid 1px;
  width: 20%;
  max-width: 50px;
  color: black;
  -moz-appearance: textfield;
  text-align: center;
}

.ReportChargerHourContentFrom >div> img,
.ReportChargerHourContentTo >div>img{
  width: 17px;
  margin-right: 5px;
  margin-left: 5px;
}


.ReportChargerHourContentFrom input[type="number"]::-webkit-inner-spin-button,
.ReportChargerHourContentFrom input[type="number"]::-webkit-outer-spin-button,
.ReportChargerHourContentTo input[type="number"]::-webkit-inner-spin-button,
.ReportChargerHourContentTo input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}


.ReportChargerButtonBox{
  display: flex;
  justify-content: space-between;
  width:100%;
  margin-top: 20px;
}

.ReportChargerButton, .CancelChargerButton {
  width: 50%;
}

.ReportChargerButton button {
  width: 140px;
  height: 40px;
  border-radius: 25px;
  border: none;
  color: black;
  background-color: #00a0fd;
  text-align: center;
  font-size: 16px;
  cursor: pointer;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.CancelChargerButton button {
  width: 140px;
  height: 40px;
  border-radius: 25px;
  border: 1px solid #00a0fd;
  color: #00a0fd;
  background-color: transparent;
  text-align: center;
  font-size: 16px;
  cursor: pointer;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.ReportChargerDescBox{
  width: 100%;
}

.ReportChargerDescBox > p {
  text-align: left;
  width: 100%;
  letter-spacing: 3px;
  text-transform: uppercase;
  color: #aaa;
  margin-bottom: 5px;
}

.ReportChargerDescBox > textarea{
  color: black;
  width: 95%;
  height: 50px;
  font-size: 20px;
  font-family: Arial, Helvetica, sans-serif;
  resize: none;
  border: none;
  border-bottom: 1px solid #00a0fd;
}


