.ResetPasswordWindow {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.ResetPasswordWindow > div {
  position: relative;
  width: calc(100% - 20px);
  height: 100%;
  padding: 0 10px;
}

.ResetPasswordLoading {
  position: absolute;
  top: calc(50% - 100px);
  left: calc(50% - 100px);
  margin: 0 !important;
  width: 200px !important;
  height: 200px !important;
  color: white !important;
  z-index: 5;
}

.ResetPasswordWindowContent {
  padding: 40px 0;
}

.ResetPasswordLogo > img {
  width: 110px;
}

.ResetPasswordForm > div > div {
  color: white !important;
  border-bottom: 1px solid rgb(76, 128, 228) !important;
  width: 230px !important;
}

.ResetPasswordForm > input::placeholder {
  color: white;
}

.ResetPasswordForm > input label {
  color: white !important;
}

.ResetPasswordForm input {
  color: white !important;
}

.ResetPasswordForm > div > div > input label {
  color: white !important;
  border-bottom: 1px solid rgb(76, 128, 228) !important;
  font-family: "Roboto-Regular" !important;
  min-width: 285px !important;
  height: 45px !important;
  font-size: 20px !important;
  opacity: 0.8 !important;
  position: relative !important;
}

.ResetPasswordForm > div > div > label {
  color: white !important;
  font-family: "Roboto-Regular";
}

.ResetPasswordHeader {
  color: white;
  font-size: 55px;
  font-family: "Sinkin Sans 300 Light";
  font-weight: lighter;
  position: relative;
  padding-top: 10px;
}

.ResetPasswordHeader2 {
  color: rgb(85, 83, 139);
  font-family: "Roboto-Regular";
  font-size: 14px;
  letter-spacing: 3px;
  position: relative;
  padding-top: 85px;
}

.ResetPasswordForm {
  display: table;
  margin-top: 11px;
  margin-left: auto;
  margin-right: auto;
}

.ResetPasswordForm > input {
  display: block;
  background: #333;
  border: none;
  border-radius: 25px;
  min-width: 300px;
  height: 50px;
  margin-bottom: 20px;
  text-align: center;
  color: white;
  font-size: 20px;
}

.ResetPasswordButton {
  padding-top: 50px;
}

.ResetPasswordButton > button {
  position: relative;
  background: rgb(93, 174, 255);
  border: none;
  border-radius: 25px;
  min-width: 180px;
  height: 40px;
  margin-left: auto;
  margin-bottom: 15px;
  text-align: center;
  color: rgb(0, 0, 0);
  font-size: 15px;
  cursor: pointer;
  font-family: "Roboto-Bold";
  letter-spacing: 1px;
}

.ResetPasswordBackButton {
  padding-top: 25px;
}

.ResetPasswordBackButton > a {
  position: relative;
  padding: 0 0 0 0;
  font-family: "Roboto-Regular";
  font-size: 15px;
  color: rgb(93, 174, 255);
  letter-spacing: 1px;
}

@font-face {
  font-family: "Sinkin Sans 300 Light";
  src: url(../../Style/Fonts/sinkin-sans.300-light.otf);
}

@font-face {
  font-family: "Roboto-Bold";
  src: url(../../Style/Fonts/Roboto-Bold.ttf);
}

@font-face {
  font-family: "Roboto-Light";
  src: url(../../Style/Fonts/Roboto-Light.ttf);
}
@font-face {
  font-family: "Roboto-Regular";
  src: url(../../Style/Fonts/Roboto-Regular.ttf);
}
