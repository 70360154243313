.RegisterWindow {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.register-checkbox {
  text-align: left;
  margin-left: 20px;
  padding: 5px;
}
.register-checkbox > input {
  vertical-align: middle;
}
.register-checkbox-desc {
  color: white;
  font-size: 12px;
  display: inline-block;
}

.register-checkbox-desc a {
  color: var(--light-blue-color);
}

.RegisterForm > div > .register-checkbox-desc {
  border-bottom: none !important;
}

.RegisterWindow > div {
  position: relative;
  width: 100%;
  height: 100%;
}

.RegisterLoading {
  position: absolute;
  top: calc(50% - 100px);
  left: calc(50% - 100px);
  margin: 0 !important;
  width: 200px !important;
  height: 200px !important;
  color: white !important;
  z-index: 5;
}

.RegisterForm > div > div {
  color: white !important;
  border-bottom: 1px solid rgb(76, 128, 228) !important;
  width: 230px !important;
  margin: 7px 0;
}

.RegisterForm > input::placeholder {
  color: white;
}

.RegisterForm > input label {
  color: white !important;
}

.RegisterForm input {
  color: white !important;
}

.RegisterForm > div > div > input label {
  color: white !important;
  border-bottom: 1px solid rgb(76, 128, 228) !important;
  font-family: "Roboto-Regular" !important;
  min-width: 285px !important;
  height: 45px !important;
  font-size: 20px !important;
  opacity: 0.8 !important;
  position: relative !important;
}

.RegisterForm > div > div > label {
  color: white !important;
  font-family: "Roboto-Regular";
}

.RegisterWindowContent {
  padding: 40px 0;
}

.RegisterLogo > img {
  width: 110px;
}

.RegisterHeader {
  color: white;
  font-size: 55px;
  font-family: "Sinkin Sans 300 Light";
  font-weight: lighter;
  position: relative;
  margin-top: 10px;
}

.RegisterHeader2 {
  color: rgb(85, 83, 139);
  font-family: "Roboto-Regular";
  font-size: 14px;
  letter-spacing: 3px;
  position: relative;
  bottom: -55px;
}

.RegisterForm {
  display: table;
  margin: 50px auto 0 auto;
}

.RegisterButton {
  padding-top: 45px;
}

.RegisterButton > button {
  background: rgb(93, 174, 255);
  border: none;
  border-radius: 25px;
  min-width: 180px;
  height: 40px;
  margin: auto;
  margin-bottom: 15px;
  text-align: center;
  color: rgb(0, 0, 0);
  font-size: 15px;
  cursor: pointer;
  font-family: "Roboto-Bold";
  letter-spacing: 1px;
}

.RegisterForm > input {
  display: block;
  background: #333;
  border: none;
  border-radius: 25px;
  min-width: 300px;
  height: 50px;
  margin-bottom: 20px;
  text-align: center;
  color: white;
  font-size: 20px;
}

.RegisterForm > button {
  display: block;
  background: #00a0fd;
  border: none;
  border-radius: 25px;
  min-width: 250px;
  height: 50px;
  margin: auto;
  margin-bottom: 25px;
  text-align: center;
  color: white;
  font-size: 20px;
  cursor: pointer;
}

.RegisterForm > a {
  display: block;
  margin-bottom: 40px;
  position: relative;
  padding: 0 0 0 0;
  top: 15px;
  font-family: "Roboto-Regular";
  font-size: 15px;
  color: rgb(93, 174, 255);
  letter-spacing: 1px;
}
