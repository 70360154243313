.ChargerFotoBox {
  width: 100%;
  height: calc(100% - 60px);
  overflow: auto;
}

.ChargerFotos {
  background-color: rgba(94, 94, 94, 0.5);
  width: 100%;
  padding: 15px 0;
  margin-top: 20px;
}
.ChargerFotoNoImages {
  margin-top: 30px;
  color: white;
}

.ChargerFotosImage {
  width: 100%;
}

.ChargerFotosImage img {
  border-radius: 5px;
  width: 80%;
}

.ChargerFotosDesc {
  color: #ffffff;
  font-size: 13px;
  width: 80%;
  margin: auto;
  padding-bottom: 15px;
}

.ChargerFotosDesc div {
  display: inline-block;
}

.ChargerFotosDesc div {
  float: left;
}

.ChargerFotosDesc div:last-child {
  float: right;
}


.AddChargerPhoto{
  width: 100px;
  height: 100px;
  border: 1px solid rgb(93, 174, 255);
  border-radius: 200px;
  margin: auto;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  margin-top: 50px;
  margin-bottom: 50px;
}

.AddChargerPhoto > svg {
  font-size: 50px;
  position: absolute;
  top: 7px;
  left: 0;
  right: 0;
  margin: auto;
  color: rgb(93, 174, 255);
}

.AddChargerPhotoText{
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  bottom: 0px;
  pointer-events: none;
  height: 40px;
  font-size: 13px;
  color: rgb(93, 174, 255)
}