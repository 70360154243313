.Notification
{
	width: 100%;
	height: 100px;
	position: absolute;
	left: 0;
	right: 0;
	bottom: 25px;
	margin: auto;
	z-index: 10;
	pointer-events: none;
	opacity: 0;
	transition: opacity 0.5s ease;
}

.Notification > div
{
	width: 100%;
	height: 100%;
	position: relative;
}

.Notification > div > div
{
	color: white;
	display: table;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	margin: 0;
	background: #222;
	padding: 10px;
	border-radius: 10px;
	max-width: calc(100% - 100px);
	word-break: break-word;
}