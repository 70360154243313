.ReservationHeader {
  position: absolute;
  z-index: 10;
  background-color: #070;
  right: 0;
  top: 0;
  width: 300px;
  min-height: 40px;
  border-radius: 0 0 0 20px;
  color: white;
  cursor: pointer;
}

.ReservationHidden {
  position: absolute;
  z-index: 10;
  background-color: #070;
  right: 0;
  top: 0;
  width: 90px;
  height: var(--navbar-height);
  border-radius: 0 0 0 10px;
  color: white;
  cursor: pointer;
}