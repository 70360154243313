.Sidebar {
  background: rgb(2,0,36);
background: linear-gradient(157deg, rgba(2,0,36,1) 0%, rgba(5,35,94,1) 100%, rgba(9,9,121,1) 100%, rgba(0,212,255,1) 100%); 
  width: 350px;
  height: calc(100% - var(--navbar-height));
  position: absolute;
  left: 0;
  bottom: 0;
  margin: auto;
  z-index: 4;
  transition: left 0.3s ease-out;
  overflow-x: hidden;
  overflow-y: scroll;
}

.SideDraw{
	position: absolute;
	width:100%;
	height: calc(100% - var(--navbar-height));
	background-color: rgb(0,0,0,0.7);
	z-index: 2;
}

.SidebarOpen {
  left: 0;
}
  
.SidebarClose {
  left: -350px;
}

.SideDrawOpen {
  left: 0;
}
  
.SideDrawClose {
  left: -100%;
}

.Sidebar > ul {
	width: 100%;
	min-height: calc(100% - 120px);
	padding: 0 !important;
	padding-bottom: 120px !important;
	position: relative;
}

.Sidebar a {
	text-decoration: none;
}

.Sidebar > ul > a:last-child {
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
}

.SidebarOptions {
	cursor: pointer;
}

.SidebarOptions:hover,
.SidebarOptions:active {
  background-color: #00a0fd;
  cursor: pointer;
  z-index: 2;
}

.SidebarOptions .MuiListItemIcon-root {
	position: relative;
}

.SidebarOptions .NewMessagesIcon {
	position: absolute;
	top: -6px;
	right: 6px;
	z-index: 3;
	background: red;
	color: white;
	width: 24px;
	height: 24px;
	border-radius: 12px;
	font-size: 18px;
	line-height: 22px;
	font-weight: bold;
	text-align: center;
}

.SidebarOptions >div> div >img{
	width: 30px;
	height:30px;
}

.SidebarOptions > div> span{
	font-size: 1.2rem;
}

.UserProfile{
	position: relative;
	width: 100%;
	height: 100px;
	margin-top: 10px;
	margin-bottom: 30px;
}

.AvatarGame .AvatarUser{
	width: 100%;
} 

.AvatarGame .AvatarUser > img{
	width: 80px;
	height: 80px;
	border-radius: 50%;

}

.AvatarGame{
	position:relative;
	margin: auto;
	display: block !important;
	text-align: center !important;
}


.SidebarBackground {
  z-index: 3;
  position: absolute;
  width: 100%;
  height: calc(100% - var(--navbar-height));;
	cursor: pointer;
	overflow: hidden;
}
