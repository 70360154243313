.NaviContent {
  position: fixed;
  width: 100%;
  height: calc(100% - var(--navbar-height));
  overflow: auto;
  pointer-events: none;
}

.NaviBox {
  width: 90%;
  max-width: 400px;
  min-width: 300px;
  height: 160px;
  background-color: rgba(0, 0, 0, 0.75);
  position: fixed;
  left: 0;
  right: 0;
  margin: auto;
  border-radius: 10px;
  margin-top: 10px;
  transition: all 0.3s ease-out;
  pointer-events: all;
  padding-bottom: 10px;
}

.NaviRouteContent {
  width: 100%;
  padding: 20px;
}

.NaviRoute {
  float: left;
  width: 10%;
}

.NaviRoute span {
  display: block;
  width: 6px;
  height: 6px;
  border-radius: 100%;
  margin-bottom: 8px;
  background: grey;
}

.NaviRoute .Tringle {
  margin: 5px 10px 2px 0px
}

.NaviRoute .Tringle > svg{
  color: grey;
}

.NaviRoute span:nth-last-child(4) {
  margin-left: 9.5px;
}

.NaviRoute span:nth-last-child(3) {
  margin-left: 9.5px;
}

.NaviRoute span:nth-last-child(2) {
  margin-left: 9.5px;
}

.NaviRoute span:last-child {
  margin-bottom: 0;
  margin-left: 6.5px;
  border: solid 2px grey;
  width: 8px;
  height: 8px;
  background: transparent;
}

.NaviSearchRoute {
  float: left;
  width: 64%;
}

.NaviSearchRoute input {
  color: white;
  margin: 0 !important;
}

.NaviSearchRoute input::placeholder {
  color: white;
}

.NaviSearchRoute input:last-child {
  margin-top: 70px;
}

.NaviButtons button {
  border: none;
  border-radius: 30px;
  width: 120px;
  height: 30px;
  letter-spacing: 1px;
  text-transform: uppercase;
  background-color: #5daeff;
  color: black;
  font-weight: bold;
  font-size: 10px;
  margin-top: 10px;
}

.NaviButtons button:hover{
  cursor: pointer;
}

.NaviButtons button:last-child {
  border: solid #5daeff 2px;
  border-radius: 30px;
  width: 110px;
  height: 30px;
  letter-spacing: 2px;
  text-transform: uppercase;
  background-color: transparent;
  color: #5daeff;
  font-weight: bold;
  font-size: 12px;
  margin-top: -20px;
  margin-left: 10px;
}

.NaviButtons div {
  color: white;
  font-weight: bold;
  font-size: 16px;
  margin-top: 10px;
}

.NaviButtons input {
  top: 150px;
  position: absolute;
  width: 30px;
  height: 25px;
  left: 7px;
  z-index: 2;
  cursor: pointer;
  opacity: 0;
}

.NaviButtons span {
  display: block;
  position: absolute;
  left: 22px;
  width: 20px;
  height: 5px;
  background-color: White;
  border-radius: 3px;
  z-index: 1;
  transition: all 0.3s ease-out;
}

.NaviButtons span:first-of-type {
  left: 10px;
  transform: rotate(-45deg);
}

.NaviButtons span:last-of-type {
  right: 0;
  transform: rotate(45deg);
}

.NaviButtons input:checked ~ span:first-of-type {
  transform: rotate(45deg);
}

.NaviButtons input:checked ~ span:last-of-type {
  transform: rotate(-45deg);
}

.NaviButtons2 {
  margin-top: 64px !important;
  text-align: left;
}