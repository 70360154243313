.find-user-query {
	background-color: #222a;
	color: white;
	border-radius: 8px;
	width: 90%;
	margin: auto;
	max-width: 400px;
	height: 40px;
	padding: 5px;
	padding-top: 8px;
	margin-bottom: 20px;
	text-align: left;
}

.find-user-query .MuiFormControl-root .MuiInputBase-root input {
	color: white;
	padding-left: 5px;
	padding-right: 5px;
}
.find-user-query .MuiInputBase-root .MuiSelect-root {
	color: white;
}
.find-user-query .MuiInputBase-root .MuiSvgIcon-root {
	color: white;
}
.find-user-query > .MuiFormControl-root {
	width: 45%;
	float: left;
}
.find-user-query > .MuiInputBase-root {
	width: 50%;
	float: left;
	margin-left: 5%;
}

.FindUserPlate, .FindUserLogin {
  margin: auto;
  height: 40px;
  background-color: rgba(94, 94, 94, 0.7);
  border-radius: 5px;
  width: 150px;
  margin-top: 10px;
}

.FindUser i:first-child {
  display: inline-block;
  margin-top: 10px;
}

.FindUserPlate input, .FindUserLogin input {
  height: 100%;
  font-size: 22px;
  width: 50px;
  padding: 0 10px;
  background-color: #0000;
  border: none;
  text-align: center;
  color: white;
}

.FULogin {
  display: inline-block;
  font-size: 18px;
	margin-left: 20px;
	font-size: 22px;
	font-weight: 500;
	line-height: 60px;
	vertical-align: top;	
}

.fu-message {
	float: right;
	margin-top: 5px;
}

.fu-message :hover {
	cursor: pointer;
}

.FUUser, .FUCar {
	background-color: white;
	width: 90%;
	max-width: 400px;
	margin: auto;
	border-radius: 8px;
	color: black;
	margin-top: 10px;
	padding: 10px;
	text-align: left;
	height: 60px;
}

.FUCar {
	padding-left: 20px;
}

.FUCar:first-child {
  margin-top: 20px;
}

.DisableField {
  opacity: 0.6
}

.FindUserLogin input {
  width: 130px;
}

.FindUserDivider {
  display: inline;
}

.FUCarPlate {
	position: relative;
	width: 130px;
	height: 29px;
	background: linear-gradient(90deg, #5EABFC 0px, #5EABFC 15px, #FFF 15px, #FFF 100%);
	border: 3px solid #000;
	border-radius: 10px;
	padding: 0;
	display: inline-block;
	vertical-align: middle;
}

.fu-car-logo {
	width: 60px;
	height: 60px;
	display: inline-block;
	vertical-align: top;
}

.fu-middle {
	display: inline-block;
	margin-left: 10px;
}

.FUCarPlate .FUBeginNumber,
.FUCarPlate .FUDots,
.FUCarPlate .FUEndNumber
{
	position: absolute;
	left: 20px;
	top: 3px;
	width: 40px;
	height: calc(100% - 10px);
	text-align: center;
	font-size: 20px;
	font-weight: bold;
	color: #000;
	z-index: 2;
	text-transform: uppercase;
}

.FUCarPlate .FUBeginNumber, .FUCarPlate .FUEndNumber {
	top: -2px;
}

.FUCar .FUCarPlate .FUDots
{
	left: 52px;
	line-height: 12px;
}

.FUCar .FUCarPlate .FUEndNumber
{
	left: 85px;
}

.FUCar .FUCarColor
{
	display: inline-block;
	vertical-align: middle;
	width: 100px;
	margin-left: 5px;
	white-space: nowrap;
	font-size: 16px;
	overflow: hidden;
	text-overflow: ellipsis;
}

.FUCar .FUColorSquare
{
	width: 25px;
	height: 25px;
	border: 2px solid black;
	border-radius: 40px;
	vertical-align: middle;
	display: inline-block;
	margin-right: 5px;
	text-align: center;
	color: black;
	background: white;
	font-size: 20px;
}

.FUAvatar {
	display: inline-block;
	margin-left: 0;
}

.FUAvatar img {
  display: inline-block;
  width: 60px;
  height: 60px;
  border-radius: 50%;
}