.Map{
	width: 100%;
  	height: calc(100vh - var(--navbar-height)) !important;
  	position: absolute;
}

.marker-circle {
	width: 15px;
	height: 15px;
	background-color: grey;
	border-radius: 50%;
	transform: translate(40px, -90px);
	box-shadow: 0px 0px 3px #333;
}

.MyLocationButton {
	margin: 10px;
	width: 40px;
	height: 40px;
	background: white;
	border-radius: 2px;
	cursor: pointer;
	box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
}

.MyLocationButton > svg {
	width: 27.5px;
	height: 27.5px;
	padding-top: 6px;
	padding-left: 1px;
	color: #666 !important;
}

.MyLocationButton:hover > svg {
	color: #333;
}

.MoveToMyLocationButton {
	margin: 10px;
	margin-top: 0;
	width: 40px;
	height: 40px;
	background: white;
	border-radius: 2px;
	cursor: pointer;
	box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
}

.MoveToMyLocationButton > svg {
	width: 30px;
	height: 30px;
	padding-top: 6px;
	padding-left: 2px;
	color: #666 !important;
}

.MoveToMyLocationButton:hover > svg {
	color: #333;
}

.WithTracking .MyLocationButton,
.WithMoving .MoveToMyLocationButton {
	background: green !important;
}

.WithTracking .MyLocationButton > svg,
.WithMoving .MoveToMyLocationButton > svg {
	color: white !important;
}

.ChargerMarker {
	transform: translate(-28.5px, -64px);
	position: absolute;
	cursor: pointer;
	z-index: 2 !important;
}

.MyPositionMarker {
	width: 46px;
	height: 64px;
	transform: translate(-23px, -64px);
	position: absolute;
	cursor: pointer;
	z-index: 2 !important;
}

.MyPositionMarker img {
	width: 100%;
	height: 100%;
}

.ChargerCluster {
	cursor: pointer;
	width: 40px;
	height: 30px;
	background-color: #5daeff;
	border-radius: 50%;
	border: solid 3px white;
	color: white;
	text-align: center;
	font-size: 14px;
	font-weight: bold;
	transform: translate(-22.5px, -22.5px);
	user-select: none;
	padding-top: 14px;
}

.MarkerInfo {
	cursor: default;
	background: #FFF;
	display: table;
	padding: 10px 20px;
	border: 2px solid black;
	border-radius: 10px;
	position: absolute;
	bottom: calc(100% + 10px);
	left: 0;
	transform: translateX(calc(-50% + 29px));
	margin: auto;
	min-width: 170px;
	z-index: 3 !important;
}

.MarkerInfo > div {
	white-space: nowrap;
}

.MarkerInfo:after {
	content: '';
	position: absolute;
	top: 100%;
	left: 50%;
	transform: translate(-50%, -50%) rotate(45deg);
	width: 10px;
	height: 10px;
	border-bottom: 2px solid black;
	border-right: 2px solid black;
	background: #FFF;
	margin-top: 1.5px;
}

@-moz-document url-prefix() {
	.MarkerInfo:after {
		margin-top: 0px;
	}
  }  

.MarkerInfo button {
	margin-top: 10px;
	background: #00A0FD;
	border: none;
	border-radius: 5px;
	width: 100%;
	padding: 0 10px;
	color: white;
	cursor: pointer;
}

.MarkerInfoClose {
	cursor: pointer;
	position: absolute;
	display: table;
	top: 0;
	right: 3px;
	font-size: 20px;
	user-select: none;
}

.MarkerInfoLoading svg {
	color: black
}

.MapLoadingScreen {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 20;
  background: url("../../assets/images/app-bg-login.jpg");
  background-repeat: no-repeat;
  background-position: 65% 50%;
  background-attachment: fixed;
  background-size: cover;
  min-height: 100vh;
}

.MapSpinner {
	position: absolute;
	top: calc(50% - 270px);
	left: calc(50% - 100px);
	margin: 0 !important;
	width: 200px !important;
	height: 200px !important;
	color: white !important;
	z-index: 5;
}