.Profile {
  color: white;
  font-size: 18px;
  padding: 20px 0;
}

.Profile-box {
  position: relative;
}

.Profile-info,
.Profile-password {
  border: 1px solid white;
  width: auto;
  width: 300px;
  margin: auto;
  padding-bottom: 20px;
  margin-bottom: 40px;
}

.Profile-box .ProfileAvatarImage {
  width: 200px;
  height: 200px;
  margin: auto;
  cursor: pointer;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
}

.Profile-box .ProfileAvatarLoading {
  position: absolute;
  top: calc(50% - 50px);
  left: calc(50% - 50px);
  margin: 0 !important;
  width: 100px !important;
  height: 100px !important;
  color: white !important;
  z-index: 5;
}

.Profile-box .ProfileAvatar {
  position: relative;
}

.ProfileForm {
  background-color: white;
  padding: 0 20px;
  border-radius: 20px;
  margin: 20px auto 0 auto;
  display: table;
  color: rgb(121, 119, 175);
  font-family: "Roboto-Bold";
  letter-spacing: 2px;
  width: 80%;
}

.ProfileForm:last-child {
  margin-bottom: 150px;
}

.ProfileForm .Profile-box > div > div {
  border-bottom: 1px solid rgb(76, 128, 228);
  width: 270px;
  margin: 20px 0;
}

.ProfileForm .Profile-box > input::placeholder {
  color: white;
}

.ProfileForm .Profile-box > input label {
  color: rgb(121, 119, 175);
}

.ProfileForm .Profile-box input {
  color: rgb(121, 119, 175);
}

.ProfileForm .Profile-box > div > div > input label {
  color: rgb(121, 119, 175);
  border-bottom: 1px solid rgb(76, 128, 228);
  font-family: "Roboto-Regular";
  min-width: 285px;
  height: 45px;
  font-size: 20px;
  opacity: 0.8;
  position: relative;
}

.ProfileForm> div > div > label {
  color: rgb(121, 119, 175);
  font-family: "Roboto-Regular";
}

.ProfileForm > button {
  display: block;
  background: transparent;
  color: rgb(93, 174, 255);
  border: 1px rgb(93, 174, 255) solid;
  border-radius: 25px;
  min-width: 250px;
  height: 50px;
  margin: auto;
  margin-bottom: 15px;
  text-align: center;
  cursor: pointer;
  font-family: "Roboto-Bold";
  font-size: 1.2rem;
  letter-spacing: 3px;
}

.avatar-button {
  display: block;
  background: #00a0fd;
  border: none;
  border-radius: 25px;
  min-width: 150px;
  height: 25px;
  margin: auto;
  margin-bottom: 15px;
  text-align: center;
  color: white;
  font-size: 20px;
  cursor: pointer;
  margin-top: 10px;
}

.ProfileAvatarChange {
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  bottom: 0px;
  background-color: rgb(0, 0, 0, 0.8);
  pointer-events: none;
  height: 60px;
  line-height: 60px;
}

.PaymentButton {
  display: block;
  background: #fff;
  color: rgb(93, 174, 255);
  border: 1px rgb(93, 174, 255) solid;
  border-radius: 25px;
  min-width: 250px;
  height: 50px;
  margin: auto;
  margin-bottom: 20px;
  text-align: center;
  cursor: pointer;
  font-family: "Roboto-Bold";
  font-size: 1.2rem;
  letter-spacing: 3px;
  margin-top: 20px;

}

.PaymentButton :hover {
  cursor: pointer;
}

.PlanContainer{
  display: table;
  margin: 30px 0;
}

.PlanOptions{
  display: table-row;
  margin-left: 40px;
}

.PlanOptionsFree{
  display: table-row;
  margin-left: 40px;
}

.PlanOptions > svg{
  vertical-align: -30px;
  font-size: 1.6rem;
  color: lime;
  display: table-cell;
  margin: 10px 0;
  margin-left: 10px;
  margin-right: 0;
  margin-bottom: 25px;
}

.PlanOptions > div {
  display: table-cell;
  border-bottom:none !important;
  margin: 10px 0;
  vertical-align: middle;
  margin-bottom: 25px;
  width: 82% !important;
  text-align: left;
}

.PlanOptionsFree > svg{
  vertical-align: -17px;
  font-size: 1.7rem;
  color: red;
  display: table-cell;
  margin: 10px 0;
  margin-left: 10px;
}

.PlanOptionsFree > svg :first-child{
  vertical-align: -15px;
  font-size: 1.6rem;
  color: lime;
  display: table-cell;
  margin: 10px 0;
  margin-left: 10px;
}

.PlanOptionsFree > div {
  display: table-cell;
  border-bottom:none !important;
  margin: 10px 0;
  vertical-align: middle;
  margin-bottom: 25px;
  width: 82% !important;
  text-align: left;
}





