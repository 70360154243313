.Payment {
  color: white;
  font-size: 18px;
  padding: 20px 0;
}

.Payment-box {
  position: relative;
}

.Payment-info,
.Payment-password {
  border: 1px solid white;
  width: auto;
  width: 300px;
  margin: auto;
  padding-bottom: 20px;
  margin-bottom: 40px;
}

.Payment-box .PaymentAvatarImage {
  width: 200px;
  height: 200px;
  border: 1px solid #888;
  border-radius: 200px;
  margin: auto;
  cursor: pointer;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  position: relative;
  overflow: hidden;
}

.Payment-box .PaymentAvatarLoading {
  position: absolute;
  top: calc(50% - 50px);
  left: calc(50% - 50px);
  margin: 0 !important;
  width: 100px !important;
  height: 100px !important;
  color: white !important;
  z-index: 5;
}

.Payment-box .PaymentAvatar {
  position: relative;
}

.PaymentForm {
  background-color: white;
  padding: 0 20px;
  border-radius: 20px;
  margin: 20px auto 0 auto;
  display: table;
  color: rgb(121, 119, 175);
  font-family: "Roboto-Bold";
  letter-spacing: 2px;
  width: 80%;
}

.PaymentForm:last-child {
  margin-bottom: 150px;
}

.PaymentForm .Payment-box > div > div {
  border-bottom: 1px solid rgb(76, 128, 228);
  width: 230px;
  margin: 20px 0;
}

.PaymentForm .Payment-box > input::placeholder {
  color: white;
}

.PaymentForm .Payment-box > input label {
  color: rgb(121, 119, 175);
}

.PaymentForm .Payment-box input {
  color: rgb(121, 119, 175);
}

.PaymentForm .Payment-box > div > div > input label {
  color: rgb(121, 119, 175);
  border-bottom: 1px solid rgb(76, 128, 228);
  font-family: "Roboto-Regular";
  min-width: 285px;
  height: 45px;
  font-size: 20px;
  opacity: 0.8;
  position: relative;
}

.PaymentForm> div > div > label {
  color: rgb(121, 119, 175);
  font-family: "Roboto-Regular";
}

.PaymentForm > button {
  display: block;
  background: transparent;
  color: rgb(121, 119, 175);
  border: 1px rgb(121, 119, 175) solid;
  border-radius: 25px;
  min-width: 250px;
  height: 50px;
  margin: auto;
  margin-bottom: 15px;
  text-align: center;
  cursor: pointer;
  font-family: "Roboto-Bold";
  font-size: 1.2rem;
  letter-spacing: 3px;
}

.avatar-button {
  display: block;
  background: #00a0fd;
  border: none;
  border-radius: 25px;
  min-width: 150px;
  height: 25px;
  margin: auto;
  margin-bottom: 15px;
  text-align: center;
  color: white;
  font-size: 20px;
  cursor: pointer;
  margin-top: 10px;
}

.PaymentAvatarChange {
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  bottom: 0px;
  background-color: rgb(0, 0, 0, 0.8);
  pointer-events: none;
  height: 60px;
  line-height: 60px;
}
