*:focus /* wyłączenie obramowań z przeglądarki na aktywnych elementach */
 {
  outline: none;
}

#root {
  background: url("../assets/images/app-bg-login.jpg");
  background-repeat: no-repeat;
  background-position: 65% 50%;
  background-attachment: fixed;
  background-size: cover;

  /* width: 100vw; */
  min-height: 100vh;
}

:root {
  --navbar-height: 56px; /* Wysokość górnego menu */
  --light-blue-color: #5EABFC;
}

* {
  scrollbar-color: rgba(128, 128, 128, 0.5) rgba(0, 0, 0, 0.2);
  scrollbar-width: thin;
}

*::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(128, 128, 128, 0.5);
}

.MainWithGradient {
  position: fixed;
  width: 100%;
  height: calc(100% - var(--navbar-height));
  background: url("../assets/images/app-bg.jpg");
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-attachment: fixed;
  background-size: cover;
  color: white;
  overflow: auto;
}

@media (min-width: 0px) and (orientation: landscape) {
  :root {
    --navbar-height: 48px;
  }
}

@media (min-width: 600px) {
  :root {
    --navbar-height: 64px;
  }
}

@font-face {
  font-family: "Sinkin Sans 300 Light";
  src: url(../Style/Fonts/sinkin-sans.300-light.otf);
}

@font-face {
  font-family: "Roboto-Bold";
  src: url(../Style/Fonts/Roboto-Bold.ttf);
}

@font-face {
  font-family: "Roboto-Light";
  src: url(../Style/Fonts/Roboto-Light.ttf);
}
@font-face {
  font-family: "Roboto-Regular";
  src: url(../Style/Fonts/Roboto-Regular.ttf);
}
