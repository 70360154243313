.ChargerOpinionBox {
  width: 100%;
  height: calc(100% - 60px);
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ChargerRatingBox {
  background-color: white;
  border-radius: 5px;
  width: 95%;
  max-width: 500px;
  margin-top: 10px;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: black;
  font-size: 18px;
  font-weight: 600;

}

.ChargerRating {
  width: 95%;
  display: flex;
  justify-content: space-around;
  margin-bottom: 10px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 5px;
  padding-top: 5px;
}

.ChargerRating:first-child{
  margin-top: 10px;
}

.ChargerRating:last-child {
  margin-bottom: 10px;
  border-bottom: none;
}

.ChargerRatingName {
  float: left;
  text-align: left;
  width: 40%;
  display: block;
  font-weight: 500;
}

.ChargeRatingScore {
  display: flex;
  justify-content: flex-end;
  float: left;
  width: 40%;
}

.ChargeRatingScore > :first-child{
  color: #00a0fd;
  margin-right: 5px;
}

.ChargeRatingScore div:hover {
  color: #00a0fd;
}
.ChargeRatingScore .MuiRating-iconFilled {
  color: #00a0fd;
}

.ChargerOpinionSumBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  width: 95%;
  max-width: 500px;
}

.ChargerOpinionSumBox > div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}


.ChargerRatingSum {
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: white;
  width: 150px;
  height: 140px;
  margin: 5px;
  color: black;
  padding: 10px;
  font-size: 1rem;
  border-radius: 5px;
}


.ChargerRatingSum div {
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 6px #ddd;
  border-radius: 50%;
  margin-top: 10px;
  width: 70px;
  height: 70px;
  font-size: 2rem;
  padding: 5px;
}

.ChargerRatingSum:hover {
  background-color: rgba(94, 94, 94, 0.7);
  transform: scale(1.03);
}

.ChargerRatingSum:hover div {
  background-color: #00a0fd;
}
