.LoginWindow {
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 2;
}

.LoginWindow > div {
  width: 100%;
  height: 100%;
}

.LoginLoading {
  position: absolute;
  top: calc(50% - 100px);
  left: calc(50% - 100px);
  margin: 0 !important;
  width: 200px !important;
  height: 200px !important;
  color: white !important;
  z-index: 5;
}

.LoginForm > div > div {
  color: white !important;
  border-bottom: 1px solid rgb(76, 128, 228) !important;
  width: 230px !important;
}

.LoginForm > input::placeholder {
  color: white;
}

.LoginForm > input label {
  color: white !important;
}

.LoginForm input {
  color: white !important;
}

.LoginForm > div > div > input label {
  color: white !important;
  border-bottom: 1px solid rgb(76, 128, 228) !important;
  font-family: "Roboto-Regular" !important;
  min-width: 285px !important;
  height: 45px !important;
  font-size: 20px !important;
  opacity: 0.8 !important;
  position: relative !important;
}

.LoginForm > div > div > label {
  color: white !important;
  font-family: "Roboto-Regular";
}

.LoginWindowContent {
  padding: 55px 0;
}

.LoginLogo > img {
  width: 110px;
}
.LoginLogo {
  position: relative;
  top: -15px;
}

.LoginHeader {
  color: white;
  font-size: 55px;
  font-family: "Sinkin Sans 300 Light";
  font-weight: lighter;
  position: relative;
  top: -5px;
}

.LoginHeader2 {
  color: rgb(85, 83, 139);
  font-family: "Roboto-Regular";
  font-size: 14px;
  letter-spacing: 3px;
  position: relative;
  bottom: -55px;
}

.LoginForm {
  margin: 50px auto 0 auto;
  display: table;
  position: static;
  top: 15px;
}

.LoginForm > ::placeholder {
  color: white;
  font-family: "Roboto-Regular";
  border-bottom: 1px solid rgb(76, 128, 228);
}
.LoginFormTextField2 {
  position: relative;
  margin-top: 15px;
  margin-bottom: 25px;
}

.Button1 {
  position: relative;
  top: 20px;
}
.Button2 {
  position: relative;
  top: 20px;
}

.Button1 > button {
  background: rgb(93, 174, 255);
  border: none;
  border-radius: 25px;
  min-width: 180px;
  height: 40px;
  margin: auto;
  margin-bottom: 15px;
  text-align: center;
  color: rgb(0, 0, 0);
  font-size: 15px;
  cursor: pointer;
  font-family: "Roboto-Bold";
  letter-spacing: 1px;
}

.Button2 > button {
  display: block;
  border-radius: 25px;
  min-width: 180px;
  height: 40px;
  margin: auto;
  margin-bottom: 15px;
  text-align: center;
  color: rgb(93, 174, 255);
  font-size: 15px;
  cursor: pointer;
  font-family: "Roboto-Bold";
  border: 1px solid rgb(93, 174, 255);
  letter-spacing: 1px;
  background: 0%;
}

.LoginForm > a {
  position: relative;
  padding: 0 0 0 0;
  top: 35px;
  font-family: "Roboto-Regular";
  font-size: 15px;
  color: rgb(93, 174, 255);
  letter-spacing: 1px;
}

@font-face {
  font-family: "Sinkin Sans 300 Light";
  src: url(../../Style/Fonts/sinkin-sans.300-light.otf);
}

@font-face {
  font-family: "Roboto-Bold";
  src: url(../../Style/Fonts/Roboto-Bold.ttf);
}

@font-face {
  font-family: "Roboto-Light";
  src: url(../../Style/Fonts/Roboto-Light.ttf);
}
@font-face {
  font-family: "Roboto-Regular";
  src: url(../../Style/Fonts/Roboto-Regular.ttf);
}
