.ChargerCommentBox {
  width: 100%;
  height: calc(100% - 60px);
  margin-top: 10px;
  overflow: auto;
}

.ChargerCommentMessage {
  width: 100%;
  max-width: 580px;
  margin-bottom: 5px;
  margin: auto;
}

.ChargerCommentInput {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 90%;
  margin: auto;
  height: 50px;
  background-color: black;
  color: rgba(170, 170, 170, 0.7);
  border-radius: 10px;
}

.ChargerCommentInput input {
  width: calc(100% - 70px);
  float: left;
  height: 20px;
  background: #0000;
  margin-left: 10px;
  margin-top: 3px;
  border: none;
  border-bottom: 1px solid #00aaff;
  color: white;
  padding: 5px;
}

.ChargerCommentInput img {
  width: 30px;
}

.Comments {
  width: 100%;
  color: #ffffff;
  font-size: 16px;
  display: flex;
  align-items: center;
}

.ChargerCommentComments {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  text-align: left;
}
.ChargerCommentContent {
  position: relative;
  background-color: white;
  width: 85%;
  max-width: 500px;
  margin-top: 10px;
  padding: 10px 10px;
  border-radius: 10px;
}

.ChargerCommentContent:first-of-type {
  margin-top: 0;
}


.ChargerCommentText{
  display: flex;
  align-items: center;
  height: 20px;
}

.ChargerCommentText > p {
  font-size: 10px;
  color: gray;
}

.ChargerCommentText > img {
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.ChargerCommentContentAndLikes{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  
}

.ChargerCommentComment{
  width: 75%;
}

.ChargerCommentComment p {
  color: black;
  overflow-x: hidden;
  word-break: break-word;
  font-size: 1rem;
  margin-left: 5px;
} 

.ChargerUserComment {
  background-color: white;
}

.LikeComment > img {
  width: 35px;
  margin-top: 4px;
  margin-left: 10px;
} 

.LikeComment{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width:23%;
  margin-left: 20px;
  margin-right: 5px;
  height: auto;
}

.LikeComment > svg {
  margin-left: 10px;
  color: red;
  width: 35px;
  height: 35px;
}


.NumberOfLikes{
  display: flex;
  color: grey;
  margin-left: 10px;
  font-size: 12px;
  font-style: italic;
}


.LoadingComment
{
	position: absolute;
	top: calc(50% - 100px);
	left: calc(50% - 100px);
	margin: 0 !important;
	width: 200px !important;
	height: 200px !important;
	color: #ddd !important;
	z-index: 5;
}

.ChargerNoComments {
  margin-top: 30px;
  color: white;
}