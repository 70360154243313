.ConversationArea {
	position: absolute;
	width: 100%;
	height: calc(100vh - var(--navbar-height));
	top: 0;
	left: 100%;
	transition: left 0.5s ease;
}

.ConversationTitle {
	color: white;
	position: relative;
	padding: 0;
	display: flex;
	align-items: center;
	padding-bottom: 10px;
}

.ConversationTitleName {
	color: white;
	font-size: 30px;
	line-height: 50px;
	width: 100%;
  height: 50px;
  background-color: transparent;
}

.ConversationMessage {
	width: calc(100% - 16px);
	margin: 12px 16px;
	clear: both;
	padding: 0 0;
	position: relative;
	min-height: 60px;
}

.ConversationMessagesList {
	width: 100%;
	max-height: calc(100% - 120px);
	overflow-y: scroll;
}

.ConversationMessageAvatar {
	width: 50px;
	height: 50px;
	position: absolute;
	left: 0;
	bottom: 10px;
	border-radius: 50%;
}

.ConversationMyMessageAvatar {
	left: inherit;
	right: 0;
}

.ConversationInfo {
	text-align: left;
	margin-left: 60px;
	max-width: calc(100% - 180px);
	display: table;
}

.ConversationMyInfo {
	text-align: right;
	margin-left: auto;
	margin-right: 60px;
}

.ConversationTime {
	color: #888;
	text-align: left;
	font-size: 0.8rem;
	padding: 1px 0;
	margin-top: -5px;
}

.ConversationMyTime {
	text-align: right;
}

.ConversationContent {
	display: table;
	background: rgb(45, 27, 167);
	color: white;
	text-align: left;
	padding: 8px;
	border-radius: 0px 5px 5px 5px;
	word-break: break-word;
}

.ConversationMyContent {
	background: white;
	border-radius: 5px 0px 5px 5px;
	color:black;
	float: right;
	position: relative;
	padding-right: 25px;
}

.ConversationSendArea {
	position: absolute;
	bottom: 0;
	width: 100%;
	height: 60px;
}

.ConversationSendInput {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 95%;
	max-width: 500px;
	height: 40px;
	background: black;
	margin: auto;
	margin-top: 10px;
	padding: 0 0px;
	border-radius: 5px;
}

.ConversationSendInput input {
	width: calc(100% - 70px);
	float: left;
	height: 20px;
	background: #0000;
	margin-left: 10px;
	margin-top: 8px;
	border: none;
	border-bottom: 1px solid #00A0FD;
	color: white;
}

.ConversationSendInput ::placeholder {
  color: #ccc;
}

.ConversationSendInput > img{
	float: right;
	margin-left: 10px;
	width: 30px;
}

@keyframes messageAnimation{
	from { transform: scale(0); }
	to { transform: scale(1); }
}

.ConversationMessage.new {
	transform: scale(1);
	transition: transform 0.5s ease;
	animation-name: messageAnimation;
	animation-duration: 0.5s;
}

.ConversationMessagesList .ConversationLoading {
	color: #00A0FD;
}

.MyMessageSending {
	position: absolute;
	bottom: 0;
	right: 5px;
}

.MyMessageSending div {
	margin: 0;
	width: 15px !important;
	height: 15px !important;
	color: #FFF;
}
