.ReservationBox {
  width: 100%;
  height: calc(100% - 60px);
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.MakeReservation {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  width: 95%;
  max-width: 500px;
  height: 200px;
  min-height: 200px;
  background: black;
  border-radius: 5px;
  margin: auto;
  margin-top: 0;
  margin-bottom: 0;
}

.ReservationErrors {
  color: red;
}

.MakeReservation p {
  color: white;
  text-align: left;
  font-size: 14px;
  font-weight: 600;
  margin-left: 10%;
  margin-bottom: 0;
}

.TitleDataReservation{
  text-align: left;
}

.ReservationDateInput{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  border-bottom: 1px solid #00a0fd;
}

.ReservationDateInput > input {
  border: none;
  width: 78%;
  height: 30px;
  border: none;
  background: transparent;
  color: rgba(255, 255, 255, 0.7);
}

.ReservationDateInput > img{
  width: 18px;
}

.InputReservationTimeBox{
  display: flex;
  flex-direction: row;
  justify-content: center;
  width:90%;
  max-width: 450px;
}

.InputReservationTimeFrom,
.InputReservationTimeTo {
  width: 50%;
  float: left;
  background-color: transparent;
  padding-top: 5px;
  padding-bottom: 5px;
  color: rgba(255, 255, 255, 0.7);
  align-items: center;
}

.InputReservationTimeFrom,
.InputReservationTimeTo{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.InputReservationTimeFrom> div>p,
.InputReservationTimeTo>div>p{
  font-size: 10px;
  margin-top: 2px;
  margin-left: 16px;
  color: #aaa
}

.InputReservationTimeFrom>div> input,
.InputReservationTimeTo>div> input {
  background-color: transparent;
  border: none;
  border-bottom: #00aaff solid 1px;
  width: 20%;
  max-width: 50px;
  color: rgba(255, 255, 255, 0.7);
  -moz-appearance: textfield;
  text-align: center;
}

.InputReservationTimeFrom >div> img,
.InputReservationTimeTo >div>img{
  width: 17px;
  margin-right: 3px;
  margin-left: 3px;
}


.InputReservationTimeFrom input[type="number"]::-webkit-inner-spin-button,
.InputReservationTimeFrom input[type="number"]::-webkit-outer-spin-button,
.InputReservationTimeTo input[type="number"]::-webkit-inner-spin-button,
.InputReservationTimeTo input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

/* .MakeReservation p:nth-last-child(5),
.MakeReservation p:nth-last-child(3) {
  width: 12%;
  float: left;
  margin-top: 0;
} */

.ReservationButton {
  width: 90%;
  max-width: 500px;
  height: 40px;
  min-height: 40px;
  max-height: 40px;
  color: black;
  font-size: 20px;
  letter-spacing: 2px;
  background-color: #00aaff;
  border: none;
  border-radius: 20px;
  margin: auto;
  margin-top: 0;
  margin-bottom: 20px;
  padding: 5px 0 5px 0;

}

.Reservations {
  background-color: white;
  width: 90%;
  max-width: 500px;
  margin: auto;
  border-radius: 8px;
  margin-top: 10px;
  padding: 5px 0;
  color: black;
  font-size: 16px;
  display: flex;
  align-items: center;
}

.ReservationCurrent {
  background-color: rgb(0, 255, 0);
}

.TitleCurrentReservation {
  font-size: 0.9rem;
  width: 40px;
  margin: -10px;
  margin-right: 5px;
  display: flex;
}

.TitleCurrentReservation > div {
  transform: rotate(-90deg);
}

.Reservations:last-child {
  margin-bottom: 10px;
}

.ReservationIcon > svg {
  color: var(--light-blue-color);
  font-size: 50px;
}
.ReservationIcon > img{
  width: 50px;
  margin: 0 5px 0 5px;
}

.ReservationDesc {
  width: 100%;
  float: left;
  text-align: left;
}

.ReservationCanceled> svg {
  margin-top: 2px;
  font-size: 50px;
  color: rgb(255, 95, 95);
}

.ChargerNoReservations{
  color: white;
  margin-top: 30px;
}
