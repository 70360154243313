.ProfileNavContent {
  margin-top: 10px;
}

.ProfileNavBox {
  display: inline-block;
  width: 30px;
  height: 30px;
  margin-right: 30px;
  margin-top: 15px;
  margin-bottom: 10px;
}

.ProfileNavBox:last-of-type {
  margin-right: 0;
}



.ProfileNavBox > a  > img {
  width: 30px;
  transition: all 0.2s ease-out;
}

.ProfileNavBox a.active svg {
  color: rgb(255, 255, 255);
}
