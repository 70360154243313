.Cars > div {
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}

.CarsList {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: auto;
  transition: left 0.5s ease;
}

.CarsDetails{
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  transition: left 0.5s ease;
}

.Cars.OpenedList .CarsList,
.Cars.OpenedDetails .CarsDetails {
  left: 0;
}

.Cars.OpenedList .CarsDetails {
  left: 100%;
}

.Cars.OpenedDetails .CarsList {
  left: -100%;
}
.CarCheckForm {
  background-color: white;
  padding: 10px;
  margin: auto;
  width: 90%;
  border-radius: 10px;
  color: black;
}

.Cars .FormField {
  text-transform: uppercase;
  width: calc(100% - 50px);
  text-align: left;
  margin: 30px auto;
  font-family: "Roboto-Light";
  letter-spacing: 3px;
  color: rgb(121, 119, 175);
}

.Cars .FormField select {
  width: calc(100% - 50px);
  height: 40px;
  background: transparent;
  border: none;
  border-bottom: 1px rgb(93, 174, 255) solid;
  appearance: none;
  font-family: Arial, Helvetica, sans-serif;
  letter-spacing: 0;
  text-transform: none;
}

.Cars .FormField select option:first-child {
  display: none;
}

.PlateNumber > div:first-child {
  margin: 20px 0;
  font-size: 1rem;
  text-transform: uppercase;
}

.PlateNumber > div > p {
  margin: 0;
  letter-spacing: 0 !important;
  text-transform: lowercase;
}

.Cars .FormField .PlateImage {
  position: relative;
  width: 90%;
  max-width: 260px;
  height: 57px;
  margin: auto;
  background: linear-gradient(90deg, #00f 0px, #00f 30px, #fff 30px, #fff 100%);
  border: 3px solid #000;
  border-radius: 10px;
  padding: 0;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.PlateImage  > div {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 95%;
  margin-left: 25px;

}


.Cars .FormField .Circle {
  position: absolute;
  right: 5%;
  width: 40px;
  height: 40px;
  display: inline-block;
  vertical-align: middle;
  margin-left: 6px;
  color: black;
  text-align: center;
  font-size: 35px;
  color: #0000;
  background-repeat: no-repeat;
  background-size: 95%;
  background-position: center;
  border-radius: 50%;
}

.Cars .FormField .PlateImage > div > .FirstPart,
.Cars .FormField .PlateImage > div > .SecondPart {
  width: 25%;
  max-width: 60px;
  height: 35px;
  border: 1px solid #000;
  border-radius: 10px;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  color: #000;
  z-index: 2;
  text-transform: uppercase;
}



.Cars .FormField .PlateImage > div >div {
  width: 41px;
  height: 110px;
  text-align: center;
  font-size: 30px;
  font-weight: bold;
  color: #000;
  display: flex;
  justify-content: center;
  align-content: center;
}

.CarsLoading {
  position: absolute;
  top: calc(50% - 100px);
  left: calc(50% - 100px);
  margin: 0 !important;
  width: 200px !important;
  height: 200px !important;
  color: white !important;
  z-index: 5;
  pointer-events: none;
}

.CarsList .Car {
  position: relative;
  margin: auto;
  width: 95%;
  height: 110px;
  background: white;
  margin-bottom: 20px;
  text-align: left;
  color: black;
  border-radius: 5px;
}

.CarsList .Car:last-child {
  margin-bottom: 0;
}

.CarsList .Car > img {
  width: 50px;
  height: 50px;
  display: inline-block;
  vertical-align: top;
  margin: 10px 10px 0 10px;
}

.CarsList .Car > .CarInfo {
  width: calc(100% - 145px);
  height: 60px;
  display: inline-block;
  vertical-align: middle;
  text-align: left;
  font-size: 20px;
  margin-top: 20px;
}

.Car .CarName {
  position: absolute;
  top: 20px;
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 700;
  margin-bottom: 5px;
}

.Car .CarPlate {
  position: absolute;
  width: 130px;
  height: 29px;
  top: 55px;
  background: linear-gradient(90deg, #00f 0px, #00f 15px, #fff 15px, #fff 100%);
  border: 3px solid #000;
  border-radius: 10px;
  padding: 0;
  display: inline-block;
  vertical-align: middle;
}

.CarPlate>div{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-left: 2px;
}


.Car .CarPlate .BeginNumber,
.Car .CarPlate .Dots,
.Car .CarPlate .EndNumber {
  width: 30px;
  height: calc(100% - 10px);
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  color: #000;
  z-index: 2;
  text-transform: uppercase;
}

.Car .CarPlate .Dots {
  left: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Car .CarPlate .EndNumber {
  left: 83px;
}

.Car .ColorSquare {
  width: 7px;
  height: 100%;
  vertical-align: middle;
  display: inline-block;
  color: black;
  background: white;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.Cars h1 {
  margin-bottom: 10px;
  font-family: "Roboto-Regular";
  font-size: 1.5rem;
}

.Cars button {
  width: 150px;
  height: 40px;
  background: rgb(93, 174, 255);
  border: none;
  border-radius: 40px;
  margin: 10px 5px;
  color: black;
  font-family: "Roboto-Bold";
  letter-spacing: 3px;
}

.Cars .AddNewCarBnt{
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  background: rgb(93, 174, 255);
  border: none;
  border-radius: 50%;
  color: black;
  font-family: "Roboto-Bold";
  letter-spacing: 3px;
  right: 20px;
  bottom: 10px;
}
.Cars .AddNewCarBnt >svg {
  font-size: 2.2rem;
}


.ButtonsOptions > button {
  width: calc(50% - 30px);
  margin: 10px 10px;
}

.GoBackButton {
  background: transparent !important;
  color: rgb(93, 174, 255) !important;
  border: 1px rgb(93, 174, 255) solid !important;
}

.MyCars {
  width: 100%;
  height: calc(100vh - var(--navbar-height) - 138px);
  overflow-y: auto;
}

.Car .EditBtn,
.Car .RemoveBtn {
  position: absolute;
  top:35px;
  text-align: center;
  cursor: pointer;
}

.Car .EditBtn{
  right: 65px;
}

.Car .RemoveBtn {
  bottom: 3px;
  right: 10px;
}

.Car .EditBtn > img,
.Car .RemoveBtn > img {
  width: 40px;
  height: 40px;
}
