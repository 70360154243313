
.MapAim {
	z-index: 1;
	position: absolute;
	margin: auto;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	width: 10px;
	height: 10px;
  cursor: pointer;
  border-radius: 50%;
  border: 10px solid  rgb(45, 27, 167);
  background-color: #00A0FD;
  box-shadow: 5px 5px 10px rgb(0, 59, 0);
  }

.MapAim img {
  width: 64px;
  height: 64px;
}

.PickerInfo {
  z-index: 1;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  color: white;
  font-size: 24px;
  width: 100px;
  height: 20px;
}

.PickerInfoSpinner {
  margin-top: 40px;
}

.PickerInfo h3 {
  padding: 10px;
  padding-top: 0;
  border-radius: 10px;
}
  
.MapPickerButton button {
position: absolute;
z-index: 1;
margin: auto;
left: 0;
right: 0;
bottom: 82px;
background: #00A0FD;
border: none;
border-radius: 25px;
width: 250px;
height: 40px;
text-align: center;
color: black;
font-size: 18px;
cursor: pointer;
text-transform: uppercase;
letter-spacing: 1px;
box-shadow: 0px 0px 15px rgb(14, 1, 88);
}

.CalcButton button {
  position: absolute;
  z-index: 1;
  margin: auto;
  left: 0;
  right: 0;
  bottom: 25px;
  background: #00A0FD;
  border: none;
  border-radius: 25px;
  width: 250px;
  height: 40px;
  text-align: center;
  color: Black;
  font-size: 18px;
  cursor: pointer;
  text-transform: uppercase;
  letter-spacing: 1px;
  box-shadow: 0px 0px 15px rgb(14, 1, 88);
  }

.MapPickerProgress {
  position: absolute !important;
  z-index: 1;
  width: 100%;
  }